import blogImage from '../images/HakkimizdaBanner.webp';
import zeroWasteImage from '../images/DijitalDonusumBanner.webp';
import greenOfficeImage from '../images/ofis.webp';
import iso1Image from '../images/ISO1.webp';
import carbonFootprintImage from '../images/ihracatBanner.webp';
import esrsImage from '../images/YapayZekaBanner.webp';
import carbonCreditImage from '../images/Investment.webp';
import image1 from '../images/LogoArkaPlansiz1.webp'

const BlogPage = () => {
    return (
      <div className="flex flex-col items-center justify-center">
        <div className="text-base lg:text-lg breadcrumbs px-5 lg:px-20 pt-8 pb-8 w-full">
          <ul>
            <li>
              <a href="/">Anasayfa</a>
            </li>
            <li>Blog</li>
          </ul>
        </div>
        <div>
          <h4 className="card-title text-3xl">BLOG YAZILARIMIZ</h4>
        </div>
        <br/>
        <div className="flex flex-row w-full gap-5 justify-center items-center px-20">
          <div className="w-full h-0.5 bg-black "></div>
          <div >
            <img alt="servis" loading="lazy" className="w-32" src={image1} />
          </div>
          <div className="w-full h-0.5 bg-black "></div>
        </div>
       
        <br />
        <br />

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 pb-16 px-5 lg:px-20">
          <div
            className="flex flex-col bg-base-100 shadow-xl rounded-box transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ transitionDuration: "400ms" }}
          >
            <figure>
              <a
                href="blog/sifir-atik-gelecek-nesillere-temiz-bir-dunya-birakmak"
                className="hover:cursor-pointer"
              >
                <img
                  className="rounded-t-box"
                  src={zeroWasteImage}
                  alt="Sıfır Atık"
                />
              </a>
            </figure>
            <div className="card-body">
              <h2 className="card-title">Dijital Dönüşüm: Geleceğe Hazırlık</h2>
              <p className="text-justify">
                Dijital dönüşüm, iş süreçlerinin dijital teknolojilerle optimize
                edilmesi, müşteri deneyimlerinin geliştirilmesi ve yenilikçi iş
                modelleri oluşturulması üzerine kurulu bir stratejidir.
              </p>
              <div className="card-actions justify-end my-2">
                <a
                  href="blog/sifir-atik-gelecek-nesillere-temiz-bir-dunya-birakmak"
                  className="link"
                >
                  Devamını oku
                </a>
              </div>
            </div>
          </div>

          <div
            className="flex flex-col bg-base-100 shadow-xl rounded-box transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ transitionDuration: "400ms" }}
          >
            <figure>
              <a
                href="blog/daha-yesil-ve-surdurulebilir-ofisler-icin-oneriler"
                className="hover:cursor-pointer"
              >
                <img
                  className="rounded-t-box"
                  src={greenOfficeImage}
                  alt="Yeşil Ofis"
                />
              </a>
            </figure>
            <div className="card-body">
              <h2 className="card-title">
                Daha Yeşil ve Sürdürülebilir Ofisler için Öneriler
              </h2>
              <p className="text-justify">
                Ofisler, hem çalışanlar hem de çevre için önemli bir etkiye
                sahiptir. Ofislerde kullanılan enerji, su ve malzemeler, önemli
                miktarda atık ve kirliliğe neden olabilir. Daha yeşil ve
                sürdürülebilir ofisler oluşturmak, bu etkiyi azaltmak ve daha
                sağlıklı bir çalışma ortamı yaratmak için önemlidir.
              </p>
              <div className="card-actions justify-end my-2">
                <a
                  href="blog/daha-yesil-ve-surdurulebilir-ofisler-icin-oneriler"
                  className="link"
                >
                  Devamını oku
                </a>
              </div>
            </div>
          </div>

          <div
            className="flex flex-col bg-base-100 shadow-xl rounded-box transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ transitionDuration: "400ms" }}
          >
            <figure>
              <a
                href="/blog/iso-14064-1-standardi"
                className="hover:cursor-pointer"
              >
                <img className="rounded-t-box" src={iso1Image} alt="ISO" />
              </a>
            </figure>
            <div className="card-body">
              <h2 className="card-title">
                ISO 50001 Standardı: Enerji Yönetimi
              </h2>
              <p className="text-justify">
                Enerji verimliliği, günümüzün en önemli küresel önceliklerinden
                biridir. Kuruluşlar, enerji tüketimlerini optimize etmek ve
                karbon ayak izlerini azaltmak için uluslararası standartlara
                ihtiyaç duyarlar. ISO 50001 standardı, enerji yönetim
                sistemlerinin kurulması, uygulanması ve iyileştirilmesi için bir
                çerçeve sunar.
              </p>
              <div className="card-actions justify-end my-2">
                <a href="/blog/iso-14064-1-standardi" className="link">
                  Devamını oku
                </a>
              </div>
            </div>
          </div>

          <div
            className="flex flex-col bg-base-100 shadow-xl rounded-box transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ transitionDuration: "400ms" }}
          >
            <figure>
              <a
                href="/ihracat-destekleri/e-ihracat-destekleri"
                className="hover:cursor-pointer"
              >
                <img
                  className="rounded-t-box"
                  src={carbonFootprintImage}
                  alt="karbon"
                />
              </a>
            </figure>
            <div className="card-body">
              <h2 className="card-title">E-İhracat Destekleri</h2>
              <p className="text-justify">
                Bakanlığımızca sunulan e-ihracat desteklerine yönelik mevzuat ve
                detaylı bilgilere buradan.
              </p>
              <div className="card-actions justify-end my-2">
                <a
                  href="/ihracat-destekleri/e-ihracat-destekleri"
                  className="link"
                >
                  Devamını oku
                </a>
              </div>
            </div>
          </div>

          <div
            className="flex flex-col bg-base-100 shadow-xl rounded-box transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ transitionDuration: "400ms" }}
          >
            <figure>
              <a
                href="/karbon-cozumleri/tubitak-1711-destekleri"
                className="hover:cursor-pointer"
              >
                <img className="rounded-t-box" src={esrsImage} alt="esrs" />
              </a>
            </figure>
            <div className="card-body">
              <h2 className="card-title">
                1711 - Yapay Zekâ Ekosistem Çağrısı
              </h2>
              <p className="text-justify">
                Bu program, Türkiye'de yapay zekâ teknolojilerinin
                geliştirilmesi ve yaygınlaştırılmasını hedefler. Yapay zekâ
                çözümlerinin müşteri ihtiyaçlarına yönelik ürünlere
                dönüştürülmesini ve Türkiye Yapay Zekâ Ekosistemini harekete
                geçirmeyi amaçlar. Ayrıca, yapay zekâ tabanlı yeniliklerin hızla
                sanayi ve kamu kurumlarına entegre edilmesini destekler.
              </p>
              <div className="card-actions justify-end my-2">
                <a
                  href="/karbon-cozumleri/tubitak-1711-destekleri"
                  className="link"
                >
                  Devamını oku
                </a>
              </div>
            </div>
          </div>

          <div
            className="flex flex-col bg-base-100 shadow-xl rounded-box transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
            style={{ transitionDuration: "400ms" }}
          >
            <figure>
              <a href="/yatirim-tesvik" className="hover:cursor-pointer">
                <img
                  className="rounded-t-box"
                  src={carbonCreditImage}
                  alt="Karbon Kredisi"
                />
              </a>
            </figure>
            <div className="card-body">
              <h2 className="card-title">YATIRIM TEŞVİK BELGESİ</h2>
              <p className="text-justify">
                Yatırım Teşvik Belgesi, yatırımların belirlenen asgari şartlara
                uygun şekilde gerçekleştirilmesi durumunda, destek unsurlarından
                faydalanma imkânı sağlayan bir belgedir. Bu belge, yatırımın
                karakteristik değerlerini içerir ve ilgili kararın amaçlarına
                uygun yatırımlar için düzenlenir. Yatırım teşvik belgesi ile
                ilgili detaylı bilgi ve başvuru süreci için bizimle iletişime
                geçin.
              </p>
              <div className="card-actions justify-end my-2">
                <a href="/yatirim-tesvik" className="link">
                  Devamını oku
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default BlogPage;