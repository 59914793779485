import welcome1 from "../images/anasayfa1.webp";
import welcome2 from "../images/anasayfa2.webp";
import welcome3 from "../images/anasayfa3.webp";
import img4 from "../images/anasayfa4.webp";
import { useState, useEffect } from "react";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";

const preloadSrcList = [welcome1, welcome2, welcome3, img4];

function preloadImage(src) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      resolve(img);
    };
    img.onerror = img.onabort = function () {
      reject(src);
    };
    img.src = src;
  });
}

const AutoSlide = () => {
  const [curr, setCurr] = useState(0);

  const slides = [
    <img alt="slide" key={0} src={welcome1} />,
    <img alt="slide" key={1} src={welcome2} />,
    <img alt="slide" key={2} src={welcome3} />,
    <img alt="slide" key={3} src={img4} />,
  ];

  useEffect(() => {
    let isCancelled = false;

    async function effect() {
      if (isCancelled) {
        return;
      }

      const imagesPromiseList = [];
      for (const i of preloadSrcList) {
        imagesPromiseList.push(preloadImage(i));
      }

      await Promise.all(imagesPromiseList);

      if (isCancelled) {
        return;
      }
    }

    effect();

    const interval = setInterval(() => {
      next();
    }, 6000);

    return () => {
      isCancelled = true;
      clearInterval(interval);
    };
  }, []);

  const prev = () => {
    setCurr((curr) => (curr === 0 ? slides.length - 1 : curr - 1));
  };

  const next = () => {
    setCurr((curr) => (curr === slides.length - 1 ? 0 : curr + 1));
  };

  const selectTitle =() => {
    var text = "";
    if(curr == 0) {
      text = "Stratejik İş Danışmanlığı, Akıllı Çözümler";
    }else if (curr == 1){
      text ="";
    }else if (curr==2){
      text= "Girişimci Destek Programı İş Geliştirme Desteği için 2024 Yılı İkinci Çağrı Yayınlandı.";
    }else {
      text = "Stratejik İş Danışmanlığı, Akıllı Çözümler";
    }
    return text ;
  }

  const subTitle= () => {
    var text = "";  
    if(curr ==0){
      text =
        "Stratejik iş danışmanlığı, akıllı çözümlerle başlar: İş süreçlerinizi optimize etmek, verimliliği artırmak ve büyümenizi hızlandırmak için yenilikçi yaklaşımlar sunuyoruz.";
    }else if (curr==1 ){
      text = "";
      
    }else if (curr==2){
      text = (
        <div className="flex flex-col items-start ">
          <h1>
            <b>DESTEK UNSURLARI</b>
          </h1>
          <li>Makine-teçhizat ve kalıp giderleri</li>
          <li>Yazılım Giderleri</li>
          <li>Hizmet alımı giderleri</li>
          <h1>
            <b>Son Başvuru Tarihi:</b> 1-30 Ağustos 2024
          </h1>
        </div>
      );
    }else {
      text =
        "Stratejik iş danışmanlığı, akıllı çözümlerle başlar: İş süreçlerinizi optimize etmek, verimliliği artırmak ve büyümenizi hızlandırmak için yenilikçi yaklaşımlar sunuyoruz.";
    }
    return text;
  }

  

  return (
    <div className="overflow-hidden relative">
      <div
        className="flex transition-transform ease-out duration-1000"
        style={{ transform: `translateX(-${curr * 100}%)` }}
      >
        {slides}
      </div>
      <div className="absolute inset-0 flex items-center justify-between p-4">
        <button
          aria-label="sliderLeftButton"
          id="sliderLeftButton"
          onClick={() => prev()}
          className="text-white z-10"
        >
          <ArrowCircleLeftIcon fontSize="large" />
        </button>
        <button
          aria-label="sliderRightButton"
          id="sliderRightButton"
          onClick={() => next()}
          className="text-white"
        >
          <ArrowCircleRightIcon fontSize="large" />
        </button>
      </div>
      <div
        className={`flex  flex-col pl-20  gap-6 w-1/2 absolute inset-0 items-center justify-center`}
      >
        <h1
          className="text-xl 
        lg:text-6xl text-white"

        >
          {selectTitle()}
        </h1>
        <h1 className="text-sm lg:text-xl self-start text-slate-100 hidden lg:flex">
         {subTitle()}
        </h1>
        
      </div>

      

      
    </div>
  );
};

export default AutoSlide;
