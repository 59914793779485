import image1 from "../images/ihracatDestekleri.webp";
import isoImage from "../images/ISO.webp";
import greenCollarImage from "../images/greenCollar.webp";
import İhracatBanner from "../images/ihracatBanner.webp";
import EİhracatBanner from "../images/EihracatBanner.webp";
import ihracatDestekleri1 from "../images/ihracatDestekleri/egitim.webp";
import ihracatDestekleri2 from "../images/ihracatDestekleri/fuar.webp";
import ihracatDestekleri3 from "../images/ihracatDestekleri/ihracatDestekleriLink3.webp";
import ihracatDestekleri4 from "../images/ihracatDestekleri/ihracatDestekleriLink4.webp";
import ihracatDestekleri5 from "../images/ihracatDestekleri/lojistik.webp";
import ihracatDestekleri6 from "../images/ihracatDestekleri/saglik.webp";
import ihracatDestekleri7 from "../images/ihracatDestekleri/ihracatDestekleriLink7.webp";
import ihracatDestekleri8 from "../images/ihracatDestekleri/ihracatDestekleriLink8.webp";


const ExportSupportsPage = () => {
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="flex w-full">
        <img alt="servis" loading="lazy" className="w-full" src={image1} />
      </div>
      <div className="text-base lg:text-lg breadcrumbs px-5 lg:px-20 pt-8 pb-8 w-full">
        <ul>
          <li>
            <a href="/">Anasayfa</a>
          </li>
          <li>
            <a href="/ihracat-destekleri">İhracat Destekleri</a>
          </li>
        </ul>
      </div>
      <h1 className="text-3xl font-bold text-center py-10">
        {" "}
        İhracat Destekleri
      </h1>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 pb-16 px-5 lg:px-20">
        {/* Card */}
        <div
          className="flex flex-col bg-base-100 shadow-xl rounded-box transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
          style={{ transitionDuration: "400ms" }}
        >
          <figure>
            <a
              href="/ihracat-destekleri/ihracat-desteklerii"
              className="hover:cursor-pointer"
            >
              <img
                className="rounded-t-box"
                src={EİhracatBanner}
                alt="sürdürülebilirlik"
              />
            </a>
          </figure>
          <div className="card-body">
            <h2 className="card-title">Mal İhracat Destekleri</h2>
            <p className="flex flex-col gap-2 pt-2">
              <p>
                Bakanlığımızca sunulan ihracat desteklerine yönelik mevzuat ve
                detaylı bilgilere buradan.
              </p>
            </p>
            <div className="card-actions justify-end my-2">
              <a
                href="/ihracat-destekleri/ihracat-desteklerii"
                className="link"
              >
                Devamını oku
              </a>
            </div>
          </div>
        </div>

        {/* Card */}
        <div
          className="flex flex-col bg-base-100 shadow-xl rounded-box transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
          style={{ transitionDuration: "400ms" }}
        >
          <figure>
            <a
              href="/ihracat-destekleri/e-ihracat-destekleri"
              className="hover:cursor-pointer"
            >
              <img
                className="rounded-t-box border border-b-slate-300"
                src={İhracatBanner}
                alt="ISO"
              />
            </a>
          </figure>
          <div className="card-body">
            <h2 className="card-title">E-İhracat Destekleri</h2>
            <p className="flex flex-col">
              <p>
                Bakanlığımızca sunulan e-ihracat desteklerine yönelik mevzuat ve
                detaylı bilgilere buradan.
              </p>
            </p>
            <div className="card-actions justify-end my-2">
              <a
                href="/ihracat-destekleri/e-ihracat-destekleri"
                className="link"
              >
                Devamını oku
              </a>
            </div>
          </div>
        </div>

        {/* Card */}
        <div
          className="flex flex-col bg-base-100 shadow-xl rounded-box  transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
          style={{ transitionDuration: "400ms" }}
        >
          <figure>
            <a
              className="hover:cursor-pointer"
              href="/ihracat-destekleri/bilisim-destekleri"
            >
              <img
                className="rounded-t-box"
                src={greenCollarImage}
                alt="Yeşil Yaka"
              />
            </a>
          </figure>
          <div className="card-body">
            <h2 className="card-title">Bilişim Sektörü Destekleri</h2>
            <p>
              Bakanlığımızca sunulan hizmet sektörü desteklerine yönelik mevzuat
              ve detaylı bilgilere buradan.
            </p>
            <div className="card-actions justify-end my-2">
              <a className="link" href="/ihracat-destekleri/bilisim-destekleri">
                Devamını oku
              </a>
            </div>
          </div>
        </div>
      </div>
      <h1 className="text-3xl font-bold text-center py-10"> Hizmet İhracatı</h1>
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-5 pb-16 px-5 lg:px-20">
        {/* Card1 */}
        <div
          className="flex flex-col bg-base-100 shadow-xl rounded-box transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
          style={{ transitionDuration: "400ms" }}
        >
          <figure>
            <a
              href="https://ticaret.gov.tr/data/628b4ae913b8765c147076df/Destek%20Program%C4%B1%20Rehberi-E%C4%9Fitim%20Hizmetleri.pdf"
              className="hover:cursor-pointer"
              target="_blank"
            >
              <img
                className="rounded-t-box"
                src={ihracatDestekleri1}
                alt="sürdürülebilirlik"
              />
            </a>
          </figure>
          <div className="card-body  ">
            <h2 className="card-title font-extrabold text-xl">
              Eğitim Hizmetleri Sektörü
            </h2>
            <p>Eğitim Hizmetleri Sektörüne Sunulan Desteklere İlişkin Rehber</p>

            <div className="card-actions justify-end my-2">
              <a
                href="https://ticaret.gov.tr/data/628b4ae913b8765c147076df/Destek%20Program%C4%B1%20Rehberi-E%C4%9Fitim%20Hizmetleri.pdf"
                className="link"
                target="_blank"
              >
                Devamını oku
              </a>
            </div>
          </div>
        </div>

        {/* Card2 */}
        <div
          className="flex flex-col bg-base-100 shadow-xl rounded-box transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
          style={{ transitionDuration: "400ms" }}
        >
          <figure>
            <a
              href="https://ticaret.gov.tr/data/663a307613b876b26075af34/Fuarc%C4%B1l%C4%B1k%20Destek%20Program%C4%B1%20Rehberi.pdf"
              className="hover:cursor-pointer"
              target="_blank"
            >
              <img
                className="rounded-t-box"
                src={ihracatDestekleri2}
                alt="sürdürülebilirlik"
              />
            </a>
          </figure>
          <div className="card-body  ">
            <h2 className="card-title font-extrabold text-xl">
              Fuarcılık Sektörü
            </h2>
            <p>
              Fuarcılık Hizmetlerine Yönelik Uygulama Usul ve Esaslarına İlişkin
              Genelge Kullanıcı Rehberi
            </p>

            <div className="card-actions justify-end my-2">
              <a
                href="https://ticaret.gov.tr/data/663a307613b876b26075af34/Fuarc%C4%B1l%C4%B1k%20Destek%20Program%C4%B1%20Rehberi.pdf"
                className="link"
                target="_blank"
              >
                Devamını oku
              </a>
            </div>
          </div>
        </div>

        {/* Card3 */}
        <div
          className="flex flex-col bg-base-100 shadow-xl rounded-box transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
          style={{ transitionDuration: "400ms" }}
        >
          <figure>
            <a
              href="https://ticaret.gov.tr/data/628b4c1113b8765c147076e9/Di%C4%9Fer%20Sekt%C3%B6rlere%20Sunulan%20Desteklere%20%C4%B0li%C5%9Fkin%20Rehber%20(2024).pdf"
              className="hover:cursor-pointer"
              target="_blank"
            >
              <img
                className="rounded-t-box"
                src={ihracatDestekleri3}
                alt="sürdürülebilirlik"
              />
            </a>
          </figure>
          <div className="card-body  ">
            <h2 className="card-title font-extrabold text-xl">
              Diğer Sektörler
            </h2>

            <p>Diğer Sektörlere Sunulan Desteklere İlişkin Rehber</p>

            <div className="card-actions justify-end my-2">
              <a
                href="https://ticaret.gov.tr/data/628b4c1113b8765c147076e9/Di%C4%9Fer%20Sekt%C3%B6rlere%20Sunulan%20Desteklere%20%C4%B0li%C5%9Fkin%20Rehber%20(2024).pdf"
                className="link"
                target="_blank"
              >
                Devamını oku
              </a>
            </div>
          </div>
        </div>

        {/* Card4 */}
        <div
          className="flex flex-col bg-base-100 shadow-xl rounded-box transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
          style={{ transitionDuration: "400ms" }}
        >
          <figure>
            <a
              href="https://ticaret.gov.tr/data/6298af6413b876a890759992/K%C3%BClt%C3%BCrel%20ve%20Yarat%C4%B1c%C4%B1%20End%C3%BCstri%20Hizmetleri%20Sekt%C3%B6r%C3%BCne%20Sunulan%20Desteklere%20%C4%B0li%C5%9Fkin%20Rehber%20(2024).pdf"
              className="hover:cursor-pointer"
              target="_blank"
            >
              <img
                className="rounded-t-box"
                src={ihracatDestekleri4}
                alt="sürdürülebilirlik"
              />
            </a>
          </figure>
          <div className="card-body  ">
            <h2 className="card-title font-extrabold text-lg">
              Kültürel ve Yaratıcı Endüstri Hizmetleri Sektörü
            </h2>
            <p>
              Kültürel ve Yaratıcı Endüstri Hizmetleri Sektörüne Sunulan
              Desteklere İlişkin Rehber
            </p>

            <div className="card-actions justify-end my-2">
              <a
                href="https://ticaret.gov.tr/data/6298af6413b876a890759992/K%C3%BClt%C3%BCrel%20ve%20Yarat%C4%B1c%C4%B1%20End%C3%BCstri%20Hizmetleri%20Sekt%C3%B6r%C3%BCne%20Sunulan%20Desteklere%20%C4%B0li%C5%9Fkin%20Rehber%20(2024).pdf"
                className="link"
                target="_blank"
              >
                Devamını oku
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-5 pb-16 px-5 lg:px-20">
        {/* Card5 */}
        <div
          className="flex flex-col bg-base-100 shadow-xl rounded-box transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
          style={{ transitionDuration: "400ms" }}
        >
          <figure>
            <a
              href="https://ticaret.gov.tr/data/62a05fd413b87637d86a4cbf/Destek%20Program%C4%B1%20Rehberi.pdf"
              className="hover:cursor-pointer"
              target="_blank"
            >
              <img
                className="rounded-t-box"
                src={ihracatDestekleri5}
                alt="sürdürülebilirlik"
              />
            </a>
          </figure>
          <div className="card-body  ">
            <h2 className="card-title font-extrabold text-xl">
              Lojistik ve Taşımacılık Hizmetleri Sektörü
            </h2>
            <p>
              Lojistik ve Taşımacılık Hizmetleri Sektörüne Sunulan Desteklere
              İlişkin Rehber
            </p>

            <div className="card-actions justify-end my-2">
              <a
                href="https://ticaret.gov.tr/data/62a05fd413b87637d86a4cbf/Destek%20Program%C4%B1%20Rehberi.pdf"
                className="link"
                target="_blank"
              >
                Devamını oku
              </a>
            </div>
          </div>
        </div>

        {/* Card6 */}
        <div
          className="flex flex-col bg-base-100 shadow-xl rounded-box transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
          style={{ transitionDuration: "400ms" }}
        >
          <figure>
            <a
              href="https://ticaret.gov.tr/data/629dab6813b876408c889af6/SA%C4%9ELIK-Destek%20Program%C4%B1%20Rehberi.pdf"
              className="hover:cursor-pointer"
              target="_blank"
            >
              <img
                className="rounded-t-box"
                src={ihracatDestekleri6}
                alt="sürdürülebilirlik"
              />
            </a>
          </figure>
          <div className="card-body  ">
            <h2 className="card-title font-extrabold text-xl">
              Sağlık ve Spor Turizmi Hizmetleri Sektörü
            </h2>
            <p>
              Sağlık ve Spor Turizmi Hizmetleri Sektörlerine Sunulan Desteklere
              İlişkin Rehber
            </p>

            <div className="card-actions justify-end my-2">
              <a
                href="https://ticaret.gov.tr/data/629dab6813b876408c889af6/SA%C4%9ELIK-Destek%20Program%C4%B1%20Rehberi.pdf"
                className="link"
                target="_blank"
              >
                Devamını oku
              </a>
            </div>
          </div>
        </div>

        {/* Card7 */}
        <div
          className="flex flex-col bg-base-100 shadow-xl rounded-box transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
          style={{ transitionDuration: "400ms" }}
        >
          <figure>
            <a
              href="https://ticaret.gov.tr/data/628ce97313b876e1e8b627bf/Ye%C5%9Fil%20Hizmetler%20Sekt%C3%B6r%C3%BCne%20Sunulan%20Desteklere%20%C4%B0li%C5%9Fkin%20Rehber%20(2024).pdf"
              className="hover:cursor-pointer"
              target="_blank"
            >
              <img
                className="rounded-t-box"
                src={ihracatDestekleri7}
                alt="sürdürülebilirlik"
              />
            </a>
          </figure>
          <div className="card-body  ">
            <h2 className="card-title font-extrabold text-xl">
              Yeşil Hizmetler Sektörü
            </h2>
            <p>Yeşil Hizmetler Sektörüne Sunulan Desteklere İlişkin Rehber</p>

            <div className="card-actions justify-end my-2">
              <a
                href="https://ticaret.gov.tr/data/628ce97313b876e1e8b627bf/Ye%C5%9Fil%20Hizmetler%20Sekt%C3%B6r%C3%BCne%20Sunulan%20Desteklere%20%C4%B0li%C5%9Fkin%20Rehber%20(2024).pdf"
                className="link"
                target="_blank"
              >
                Devamını oku
              </a>
            </div>
          </div>
        </div>

        {/* Card8 */}
        <div
          className="flex flex-col bg-base-100 shadow-xl rounded-box transition-transform scale-x-100 scale-y-100 hover:scale-x-105 hover:scale-y-105"
          style={{ transitionDuration: "400ms" }}
        >
          <figure>
            <a
              href="https://ticaret.gov.tr/data/628dd7d813b8763d803c33a1/Y%C3%B6netim%20Dan%C4%B1%C5%9Fmanl%C4%B1%C4%9F%C4%B1%20Hizmetleri%20Sekt%C3%B6r%C3%BCne%20Sunulan%20Desteklere%20%C4%B0li%C5%9Fkin%20Rehber%20(2024).pdf"
              className="hover:cursor-pointer"
              target="_blank"
            >
              <img
                className="rounded-t-box"
                src={ihracatDestekleri8}
                alt="sürdürülebilirlik"
              />
            </a>
          </figure>
          <div className="card-body  ">
            <h2 className="card-title font-extrabold text-xl">
              Yönetim Danışmanlığı Sektörü
            </h2>
            <p>
              Yönetim Danışmanlığı Hizmetleri Sektörüne Sunulan Desteklere
              İlişkin Rehber
            </p>

            <div className="card-actions justify-end my-2">
              <a
                href="https://ticaret.gov.tr/data/628dd7d813b8763d803c33a1/Y%C3%B6netim%20Dan%C4%B1%C5%9Fmanl%C4%B1%C4%9F%C4%B1%20Hizmetleri%20Sekt%C3%B6r%C3%BCne%20Sunulan%20Desteklere%20%C4%B0li%C5%9Fkin%20Rehber%20(2024).pdf"
                className="link"
                target="_blank"
              >
                Devamını oku
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExportSupportsPage;
